import { lazy } from 'react';

const ConnectStream = lazy(() => import('../pages//ConnectStream'));
const AdminEvents = lazy(() => import('../pages/admin/AdminEvents'));
const AdminContacts = lazy(() => import('../pages/admin/AdminContacts'));
const AdminChannel = lazy(() => import('../pages/admin/AdminChannel'));
const Meetings = lazy(() => import('../pages/admin/Meetings'));
const Users = lazy(() => import('../pages/admin/Users'));
const Calendar = lazy(() => import('../pages/Calendar'));
const Contact = lazy(() => import('../pages/Contact'));
const ChannelsRoom = lazy(() => import('../pages/ChannelsRoom'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const RecentMeetings = lazy(() => import('../pages/RecentMeetings'));
const UpcomingMeetings = lazy(() => import('../pages/UpcomingMeetings'));
const UserProfile = lazy(() => import('../pages/UserProfile'));
const Signup = lazy(() => import('../pages/Signup'));
const Login = lazy(() => import('../pages/Login'));
const SignupConfirm = lazy(() => import('../pages/SignupConfirm'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const Room = lazy(() => import('../pages/Room'));
const Channels = lazy(() => import('../pages/Channels'));
const ContactUsCover = lazy(() => import('../pages/ContactUs'));
const CreateChannel = lazy(() => import('../pages/CreateChannel'));
const ChannelRoom = lazy(() => import('../pages/ChannelRoom'));
const EditChannel = lazy(() => import('../pages/EditChannel'));
const MyChannels = lazy(() => import('../pages/MyChannels'));
const Channel = lazy(() => import('../pages/Channel'));
const AdminDashboard = lazy(() => import('../pages/admin/AdminDashboard'));


const routes = [
    // dashboard
    {
        path: '/',
        element: <Dashboard />,
    },
    {
        path: '/recent-mettings',
        element: <RecentMeetings />,
    },
    {
        path: '/upcoming-mettings',
        element: <UpcomingMeetings />,
    },
    {
        path: '/contacts',
        element: <Contact />,
    },
    {
        path: '/calendar',
        element: <Calendar />,
    },
    
    {
        path: '/channel',
        element: <Channel />,
        layout: 'blank',
    },
    {
        path: '/channels',
        element: <Channels />,
    },
    {
        path: '/create-channel',
        element: <CreateChannel />,
    },
    {
        path: '/login',
        element: <Login />,
        layout: 'blank',
    },
    {
        path: '/signup',
        element: <Signup />,
        layout: 'blank',
    },
    {
        path: '/confirm-signup',
        element: <SignupConfirm />,
        layout: 'blank',
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />,
        layout: 'blank',
    },
    {
        path: '/reset-password',
        element: <ResetPassword />,
        layout: 'blank',
    },
    {
        path: '/my-channel',
        element: <MyChannels />
    },
    {
        path: '/edit-channel/:id',
        element: <EditChannel />
    },
    {
        path: '/channels/:meetId',
        element: <ChannelsRoom />
    },
    {
        path: '/channel/:meetId',
        element: <ChannelRoom />,
        layout: 'blank'
    },
    {
        path: '/connect-stream',
        element: <ConnectStream />
    },
    {
        path: '/profile',
        element: <UserProfile />
    },
    {
        path: '/room/:meetId',
        element: <Room />
    },
    {
        path: '/contact-us',
        element: <ContactUsCover />
    },
    {
        path : '/admin/',
        element : <AdminDashboard/>,
        layout : 'admin'
    },
    {
        path : '/admin/dashboard',
        element : <AdminDashboard/>,
        layout : 'admin'
    },
    {
        path : '/admin/users',
        element : <Users/>,
        layout : 'admin'
    },
    {
        path : '/admin/meetings',
        element : <Meetings/>,
        layout : 'admin'
    },
    {
        path : '/admin/channels',
        element : <AdminChannel/>,
        layout : 'admin'
    },
    {
        path : '/admin/contacts',
        element : <AdminContacts/>,
        layout : 'admin'
    },
    {
        path : '/admin/events',
        element : <AdminEvents/>,
        layout : 'admin'
    }
];

export { routes };
