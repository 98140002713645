import React, { Fragment, useContext, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import IconX from '../Icon/IconX';
import { AuthContext } from '../../providers/AuthProvider';
import { toast } from 'react-toastify';
import { api } from '../../services/api';
import { useNavigate } from 'react-router-dom';


export default function InstantMeet({ iopen, setIopen }) {
    const { handleGetCookies } = useContext(AuthContext)
    const [name, setName] = useState('');
    const [slug, setSlug] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSlugName = async (ename) => {
        setSlug(ename)
        if (ename) {
            let userCookies = handleGetCookies('blockstarUser');
            let formData = new FormData();
            formData.append("name", ename);
            formData.append("token", userCookies);

            let response = await api.post(`/check-meet-name.php`, formData);
            if (response && response.data && response.data.status === 'error') {
                setError(response.data.message);
            }
            else {
                setError('');
            }
        }
        else {
            setError('please enter valid name');
        }
    }

    const handleSubmit = async () => {

        try {
            if (name && slug) {
                let userCookies = handleGetCookies('blockstarUser');
                let formData = new FormData();
                formData.append("name", name);
                formData.append("slug", slug);
                formData.append("token", userCookies);

                let response = await api.post(`/add-instant-meeting.php`, formData);
                if (response && response.data && response.data.status === 'success') {
                    setIopen(false)
                    toast.success(response.data.message);
                    response.data.data.password ? navigate(`/room/${response.data.data.url_slug}?password=${response.data.data.password}`) : navigate(`/room/${response.data.data.url_slug}`);
                }
                else {
                    toast.error(response.data.message);
                    return false;
                }

            } else {
                toast.error('Invalid data found.')
            }
        }
        catch (err) {
            console.log(err.message);
            toast.error(err.message);
        }
    }

    return (
        <Transition appear show={iopen} as={Fragment}>
            <Dialog as="div" open={iopen} onClose={() => setIopen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0" />
                </Transition.Child>
                <div id="fadein_left_modal" className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen px-4">
                        <Dialog.Panel className="panel animate__animated animate__fadeInUp my-2 w-full max-w-lg overflow-hidden rounded-lg border-0 p-0 text-black dark:text-white-dark">
                            <div className="flex items-center justify-between px-5 py-3">
                                <div>
                                    <h5 className="text-lg font-bold">Enter your meeting name</h5>

                                </div>
                                <button onClick={() => setIopen(false)} type="button" className="text-white-dark hover:text-dark">
                                    <IconX />
                                </button>
                            </div>
                            <p className='px-5 py-0'>Create an instant meeting and share with participants</p>
                            <div className="p-5 pt-1 mt-2">
                                <div class="mb-2">
                                    <form>
                                        <div class="mb-2">
                                            <div>
                                                <label for="name">Meeting Name</label>
                                                <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter Name meeting name or topic" class="form-input" />
                                            </div>

                                        </div>
                                        <div className='mb-2'>
                                            <div>
                                                <label for="url">Url Slug</label>
                                                <input value={slug} onChange={(e) => handleSlugName(e.target.value)} type="text" placeholder="Enter meeting url slug" class="form-input" />
                                            </div>
                                            <p className='text-danger'>{error}</p>
                                        </div>

                                        <button type="button" disabled={error} onClick={() => handleSubmit()} class="btn btn-primary">Start Meeting</button>
                                    </form>
                                </div>

                            </div>
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
