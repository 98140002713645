import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { toggleSidebar } from '../store/themeConfigSlice';
import { useEffect } from 'react';
import IconCaretsDown from './Icon/IconCaretsDown';
// import IconMenuContacts from './Icon/Menu/IconMenuContacts';
import logoImg from '../assets/images/logo.svg';
import logoWhiteImg from '../assets/images/logo-white.png';
// import ScheduleMeet from './Modal/ScheduleMeet';
import IconMenuUsers from './Icon/Menu/IconMenuUsers';
import IconMenuDashboard from './Icon/Menu/IconMenuDashboard';
// import InstantMeet from './Modal/InstantMeet';
// import IconMenuCalendar from './Icon/Menu/IconMenuCalendar';
import IconMenuNotes from './Icon/Menu/IconMenuNotes';
import IconMenuForms from './Icon/Menu/IconMenuForms';
import IconMenuElements from './Icon/Menu/IconMenuElements';
import IconArrowBackward from './Icon/IconArrowBackward';
// import IconMenuDatatables from './Icon/Menu/IconMenuDatatables';
// import IconMenuComponents from './Icon/Menu/IconMenuComponents';



const AdminSidebar = () => {
    const themeConfig = useSelector((state) => state.themeConfig);
    const semidark = useSelector((state) => state.themeConfig.semidark);
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
  



    useEffect(() => {
        const selector = document.querySelector('.sidebar ul a[href="' + window.location.pathname + '"]');
        if (selector) {
            selector.classList.add('active');
            const ul = selector.closest('ul.sub-menu');
            if (ul) {
                let ele = ul.closest('li.menu').querySelectorAll('.nav-link') || [];
                if (ele.length) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            }
        }
    }, []);

    useEffect(() => {
        if (window.innerWidth < 1024 && themeConfig.sidebar) {
            dispatch(toggleSidebar());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <div className={semidark ? 'dark' : ''}>
            <nav
                className={`sidebar fixed min-h-screen h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-50 transition-all duration-300 ${semidark ? 'text-white-dark' : ''}`}
            >
                <div className="bg-white dark:bg-black h-full">
                    <div className="flex justify-between items-center px-4 py-3">
                        <NavLink to="/" className="main-logo flex items-center shrink-0">
                            {themeConfig.theme === 'light' ? (
                                <img className="logo-img ml-[5px] flex-none" src={logoWhiteImg} alt="logo" />
                            ) : (
                                <img className="logo-img ml-[5px] flex-none" src={logoImg} alt="logo" />
                            )}
                        </NavLink>

                        <button
                            type="button"
                            className="collapse-icon w-8 h-8 rounded-full flex items-center hover:bg-gray-500/10 dark:hover:bg-dark-light/10 dark:text-white-light transition duration-300 rtl:rotate-180"
                            onClick={() => dispatch(toggleSidebar())}
                        >
                            <IconCaretsDown className="m-auto rotate-90" />
                        </button>
                    </div>
                    <PerfectScrollbar className="h-[calc(100vh-80px)] relative">
                        <ul className="relative font-semibold space-y-0.5 p-4 py-0">
                            <li className="nav-item">
                                <ul>
                                    <li className="nav-item">
                                        <NavLink to="/admin/dashboard" className="group">
                                            <div className="flex items-center">
                                                <IconMenuDashboard className="group-hover:!text-primary shrink-0" />
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#fff] dark:group-hover:text-white-dark">{t('DashBoard')}</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/admin/users" className="group">
                                            <div className="flex items-center">
                                                <IconMenuUsers className="group-hover:!text-primary shrink-0" />
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#fff] dark:group-hover:text-white-dark">{t('Users')}</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/admin/meetings" className="group">
                                            <div className="flex items-center">
                                                <IconMenuElements className="group-hover:!text-primary shrink-0" />
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#fff] dark:group-hover:text-white-dark">{t('Meetings')}</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/admin/channels" className="group">
                                            <div className="flex items-center">
                                                <IconMenuNotes className="group-hover:!text-primary shrink-0" />
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#fff] dark:group-hover:text-white-dark">{t('Channels')}</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/admin/contacts" className="group">
                                            <div className="flex items-center">
                                                <IconMenuForms className="group-hover:!text-primary shrink-0" />
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#fff] dark:group-hover:text-white-dark">{t('Contacts List')}</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/admin/events" className="group">
                                            <div className="flex items-center">
                                                <IconMenuForms className="group-hover:!text-primary shrink-0" />
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#fff] dark:group-hover:text-white-dark">{t('Events')}</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/" className="group">
                                            <div className="flex items-center">
                                                <IconArrowBackward className="group-hover:!text-primary shrink-0" />
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#fff] dark:group-hover:text-white-dark">{t('Back to User')}</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                    
                                </ul>
                            </li>
                        </ul>
                    </PerfectScrollbar>
                </div>
            </nav>

        </div>
    );
};

export default AdminSidebar;
