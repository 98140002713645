import React, { Fragment, useContext, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import IconX from '../Icon/IconX';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';
import { toast } from 'react-toastify';
import { AuthContext } from '../../providers/AuthProvider';
import { api } from '../../services/api';


export default function ScheduleMeet({ sopen, setSopen }) {
    const { handleGetCookies } = useContext(AuthContext)
    const [name, setName] = useState('');
    const [type, setType] = useState(2);
    const [password, setPassword] = useState('');
    const [ispassword, setIspassword] = useState(2);
    const [repeat, setRepeat] = useState(0);
    const [starttime, setStarttime] = useState('');
    const [endtime, setEndtime] = useState('');
    const [error, setError] = useState('');
    const [slug, setSlug] = useState('');
    // const navigate = useNavigate()

    const handleChangeName = async (ename) => {
        setSlug(ename)
        if (ename) {
            let userCookies = handleGetCookies('blockstarUser');
            let formData = new FormData();
            formData.append("name", ename);
            formData.append("token", userCookies);

            let response = await api.post(`/check-meet-name.php`, formData);
            if (response && response.data && response.data.status === 'error') {
                setError(response.data.message);
            }
            else {
                setError('');
            }
        }
        else {
            setError('please enter valid name');
        }
    }



    const handleSubmit = async () => {
        try {
            if (name && starttime && endtime && slug) {
                let userCookies = handleGetCookies('blockstarUser');
                let formData = new FormData();
                formData.append("name", name);
                formData.append("is_schedule", 1);
                formData.append("start_time", Math.floor(new Date(starttime[0]).getTime() / 1000));
                formData.append("end_time", Math.floor(new Date(endtime[0]).getTime() / 1000));
                formData.append("is_private", type);
                formData.append("password", password);
                formData.append("is_password", ispassword);
                formData.append("token", userCookies);
                formData.append("slug", slug);
                formData.append("repeat", repeat);

                let response = await api.post(`/add-meeting.php`, formData);
                if (response && response.data && response.data.status === 'success') {
                    toast.success(response.data.message);
                    window.location.reload();
                }
                else {
                    toast.error(response.data.message);
                    return false;
                }

            } else {
                toast.error('Invalid data found.')
            }
        }
        catch (err) {
            console.log(err.message);
            toast.error(err.message);
        }
    }


    return (
        <Transition appear show={sopen} as={Fragment}>
            <Dialog as="div" open={sopen} onClose={() => setSopen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0" />
                </Transition.Child>
                <div id="fadein_left_modal" className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen px-4">
                        <Dialog.Panel className="panel animate__animated animate__fadeInUp my-2 w-full max-w-lg overflow-hidden rounded-lg border-0 p-0 text-black dark:text-white-dark">
                            <div className="flex items-center justify-between px-5 py-3">
                                <div>
                                    <h5 className="text-lg font-bold">Schedule a meeting</h5>

                                </div>
                                <button onClick={() => setSopen(false)} type="button" className="text-white-dark hover:text-dark">
                                    <IconX />
                                </button>
                            </div>
                            <div className="p-5 pt-1">
                                <div class="mb-2">
                                    <form>
                                        <div class="mb-2">
                                            <div>
                                                <label for="name">Meeting Name</label>
                                                <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter Name meeting name or topic" class="form-input" />
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                            <div>
                                                <label for="name">Url Slug</label>
                                                <input value={slug} onChange={(e) => handleChangeName(e.target.value)} type="text" placeholder="Enter Name meeting url slug" class="form-input" />
                                            </div>
                                            <p className='text-danger'>{error}</p>
                                        </div>
                                        <div className='mb-2'>
                                            <label htmlFor="repeat">Repeat</label>
                                            <select id="repeat" className="form-select text-white-dark" onChange={(e) => setRepeat(e.target.value)}>
                                                <option value="0" selected={repeat === "0"}>None</option>
                                                <option value="1" selected={repeat === "1"}>Every Day</option>
                                                <option value="7" selected={repeat === "7"}>Every Week</option>
                                                <option value="14" selected={repeat === "14"}>Every Bi-weekly</option>
                                                <option value="30" selected={repeat === "30"}>Every Month</option>
                                            </select>
                                        </div>
                                        <div class="mb-2">
                                            <label for="billingName">Meeting Type</label>
                                            <label className="inline-flex mx-2">
                                                <input onChange={(e) => setType(2)} type="radio" name="type" className="form-radio" checked={type === 2} />
                                                <span>Public</span>
                                            </label>
                                            <label className="inline-flex mx-2">
                                                <input onChange={(e) => setType(1)} type="radio" name="type" className="form-radio" checked={type === 1} />
                                                <span>Private</span>
                                            </label>
                                        </div>
                                        {type === 1 &&
                                            <>
                                                <div class="mb-2">
                                                    <label for="billingName">Set Password</label>
                                                    <label className="inline-flex mx-2">
                                                        <input onChange={(e) => setIspassword(1)} type="radio" name="password" className="form-radio" checked={ispassword === 1} />
                                                        <span>Yes</span>
                                                    </label>
                                                    <label className="inline-flex mx-2">
                                                        <input onChange={(e) => setIspassword(2)} type="radio" name="password" className="form-radio" checked={ispassword === 2} />
                                                        <span>No</span>
                                                    </label>
                                                </div>
                                                {ispassword === 1 &&
                                                    <div class="mb-2">
                                                        <div>
                                                            <label for="password">Meeting Password</label>
                                                            <input value={password} onChange={(e) => setPassword(e.target.value)} id="password" type="password" placeholder="Enter Password" class="form-input" />
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }


                                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                                            <div>
                                                <label for="billingName">Start Time</label>
                                                <Flatpickr
                                                    data-enable-time
                                                    options={{
                                                        enableTime: true,
                                                        dateFormat: 'Y-m-d H:i',
                                                        minDate: moment().format('YYYY-MM-DD HH:mm')
                                                    }}

                                                    value={starttime}
                                                    className="form-input"
                                                    onChange={(date2) => setStarttime(date2)}
                                                />
                                            </div>
                                            <div>
                                                <label for="billingName">End Time</label>
                                                <Flatpickr
                                                    data-enable-time
                                                    options={{
                                                        enableTime: true,
                                                        dateFormat: 'Y-m-d H:i',
                                                        minDate: starttime?.[0]
                                                    }}
                                                    value={endtime}
                                                    className="form-input"
                                                    onChange={(date2) => setEndtime(date2)}
                                                />
                                            </div>

                                        </div>

                                        <button disabled={error} onClick={() => handleSubmit()} type="button" class="btn btn-primary">Add</button>
                                    </form>
                                </div>

                            </div>
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
