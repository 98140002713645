import { createBrowserRouter } from 'react-router-dom';
import DefaultLayout from '../components/Layouts/DefaultLayout';
import BlankLayout from '../components/Layouts/BlankLayout';
import { routes } from './routes';
import localstorage from 'local-storage';
import AdminLayout from '../components/Layouts/AdminLayout';

const finalRoutes = routes.map((route) => {
    let userCookies = localstorage.get('blockstarUser');
    
    return {
        ...route,
        element: route.layout === 'blank' || userCookies === null  ? <BlankLayout>{route.element}</BlankLayout> : route.layout === 'admin' ? <AdminLayout>{route.element}</AdminLayout>  :  <DefaultLayout>{route.element}</DefaultLayout> 
    };
});

const router = createBrowserRouter(finalRoutes);

export default router;
